import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkCard, UitkCardLink } from "@egds/react-core/cards";
import { UitkFigure, UitkImage } from "@egds/react-core/images";
import { UitkScrim } from "@egds/react-core/scrim";
import { UitkText, UitkSubheading } from "@egds/react-core/text";

import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { FullBleedImageCardProps } from "components/flexComponents/StarRatingLinks/typings";

const FullBleedImageCard = (props: FullBleedImageCardProps) => {
  const { text, url, imageUrl, hotelCount } = props.hotelStarRating;
  const { formatText } = useLocalization();

  return (
    <UitkCard>
      <UitkFigure>
        <UitkImage src={imageUrl} alt={text} placeholderImage lazyLoading="lazy" />
        <UitkScrim>
          <UitkSubheading>{text}</UitkSubheading>
          <UitkText size={300}>{formatText("starRatingLinks.numberProperties", hotelCount)}</UitkText>
        </UitkScrim>
      </UitkFigure>

      <UitkCardLink>
        <TrackedLink
          moduleName="starRatingLinks"
          rfrr="hotelStarRatingLink"
          href={url}
          aria-label={text}
          data-testid={`star-rating-links-full-bleed-image-card-${props.index}`}
        >
          {text}
        </TrackedLink>
      </UitkCardLink>
    </UitkCard>
  );
};

export default FullBleedImageCard;
