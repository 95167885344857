import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkCard, UitkCardLink } from "@egds/react-core/cards";
import { UitkFigure, UitkImage } from "@egds/react-core/images";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkText } from "@egds/react-core/text";
import { UitkRating } from "@egds/react-core/rating";
import { UitkIcon } from "@egds/react-core/icons";

import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { ratingIcon } from "components/utility/RatingUtil";
import { ThumbnailImageLeftCardProps } from "../typings";

const ThumbnailImageLeftCard = (props: ThumbnailImageLeftCardProps) => {
  const { hotelStarRating, context, index } = props;
  const { text, url, imageUrl } = hotelStarRating;
  const starRating = text[0];
  const { formatText } = useLocalization();

  return (
    <UitkCard padded>
      <UitkLayoutGrid columns={3} space="two">
        <UitkLayoutGridItem colSpan={1}>
          <UitkFigure ratio={UitkFigure.AspectRatio.R1_1} className="uitk-card-roundcorner-all">
            <UitkImage src={imageUrl} alt={text} placeholderImage />
          </UitkFigure>
        </UitkLayoutGridItem>

        <UitkLayoutGridItem colSpan={2}>
          <UitkLayoutFlex justifyContent="space-between" alignItems="center">
            <UitkLayoutFlex direction="column">
              <UitkText size={400} weight="medium" theme="emphasis">
                {text}
              </UitkText>
              <UitkRating
                rating={starRating}
                icon={ratingIcon(context.locale, context.site.id)}
                className={context.site.brand}
              />
            </UitkLayoutFlex>

            <UitkIcon
              name="chevron_right"
              id={`star-rating-links-thumbnail-image-left-hotel-star-rating-${index}`}
              title={formatText("starRatingLinks.iconTitle", starRating)}
            />
          </UitkLayoutFlex>
        </UitkLayoutGridItem>
      </UitkLayoutGrid>

      <UitkCardLink>
        <TrackedLink
          moduleName="starRatingLinks"
          rfrr="hotelStarRatingLink"
          href={url}
          aria-label={text}
          data-testid={`star-rating-links-thumbnail-image-left-card-${text}`}
        >
          {text}
        </TrackedLink>
      </UitkCardLink>
    </UitkCard>
  );
};

export default ThumbnailImageLeftCard;
