import * as React from "react";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkHeading, UitkSubheading } from "@egds/react-core/text";
import { AnalyticsConfig } from "@egds/react-core/utils";
import { UitkSpacing } from "@egds/react-core/spacing";

import { viewTypes } from "./starRatingLinksUtils";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { StarRatingLinksProps, StarRatingCard, TopLevelComponentClassName } from "./typings";
import { StarRatingLinksFlexModuleResult } from "typings/microserviceModels/star-rating-links-flex-module";
import { withStores } from "stores";
import IsomorphicCarousel from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const StarRatingLinks = withStores(
  "analytics",
  "flexModuleModelStore"
)(
  observer((props: StarRatingLinksProps) => {
    const { templateComponent, flexModuleModelStore, context } = props;

    if (!templateComponent) {
      return null;
    }

    const {
      metadata: { id },
      config: { fmTitleId },
    } = templateComponent;
    const fmId = getFmId(templateComponent);

    const model = flexModuleModelStore.getModel(id) as StarRatingLinksFlexModuleResult | null;
    const { formatText } = useLocalization();

    if (!model) {
      return null;
    }

    const { title, subheading, links, view } = model;

    if (!links?.length) {
      return null;
    }

    const hotelStarRatingCardKeyHelper = new ItemKeyHelper("hotelStarRatingCard");

    /* istanbul ignore next */
    const carouselAnalytics: AnalyticsConfig = {
      id: "LP.StarRatingLinks",
      callback: (theId, description) => {
        const flexTrackingInfo: FlexTrackingInfo = {
          moduleName: theId,
          action: Action.SCROLL,
          linkName: description,
        };
        sendDelayedTrackEvent(flexTrackingInfo, props.analytics);
      },
    };

    const StarRatingCard = viewTypes.starRatingCard[view as keyof StarRatingCard];
    if (!StarRatingCard) {
      return null;
    }

    return (
      <UitkSpacing padding={{ block: "three" }}>
        <div
          className={viewTypes.topLevelComponentClassName[view as keyof TopLevelComponentClassName]}
          id={id}
          data-fm={fmId}
          data-fm-title-id={fmTitleId}
        >
          {Boolean(title) && (
            <UitkHeading tag="h2" size={4}>
              {title}
            </UitkHeading>
          )}

          {Boolean(subheading) && <UitkSubheading tag="h3">{subheading}</UitkSubheading>}

          <UitkSpacing margin={{ blockstart: "three" }}>
            <div>
              <IsomorphicCarousel
                analytics={carouselAnalytics}
                buttonText={{
                  nextButton: formatText("carousel.item.next"),
                  prevButton: formatText("carousel.item.prev"),
                }}
                carouselName="starRatingLinksCarousel"
                itemsVisible={{ lg: 3, md: 3, sm: 1 }}
                pageBy={1}
                peek
              >
                {links.map((hotelStarRating, linkIndex) => (
                  <StarRatingCard
                    key={hotelStarRatingCardKeyHelper.next()}
                    hotelStarRating={hotelStarRating}
                    context={context}
                    index={linkIndex}
                  />
                ))}
              </IsomorphicCarousel>
            </div>
          </UitkSpacing>
        </div>
      </UitkSpacing>
    );
  })
);

export default StarRatingLinks;
