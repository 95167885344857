import FullBleedImageCard from "./components/FullBleedImageCard";
import ThumbnailImageLeftCard from "./components/ThumbnailImageLeftCard";
import { ViewTypes } from "./typings";

export const viewTypes: ViewTypes = {
  topLevelComponentClassName: {
    "full-bleed-image-card": "StarRatingLinksFullBleedImageCard",
    "thumbnail-image-left-card": "StarRatingLinksThumbnailImageLeftCard",
  },
  starRatingCard: {
    "full-bleed-image-card": FullBleedImageCard,
    "thumbnail-image-left-card": ThumbnailImageLeftCard,
  },
};
